
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { ApiMerchant, ApiContacts, ApiBase } from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import {
  commonChangeDefaultDate,
  setModuleBCN,
} from "@/core/directive/function/common";
import _ from "lodash";

export default defineComponent({
  name: "contacts-information",
  components: {},
  setup() {
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const deleteButton = ref<HTMLElement | null>(null);
    const route = useRoute();
    const router = useRouter();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();
    const { t } = useI18n();
    const formData = ref({
      id: route.params.id,
      salutation: "",
      firstname: "",
      lastname: "",
      email: "",
      merchant_id: 0,
      position: "",
      phone: "",
      mobile: "",
      report_to: 0,
      department: "",
      decision_level: 0,
      wechat: "",
      whats_app: "",
      fax: "",
      private_phone: "",
      other_phone: "",
      other_phone_notice: "",
      birthday: "",
      assistant: "",
      assistant_email: "",
      assistant_phone: "",
      overall_notes: "",
      is_fulfillment_order_contact: 0,
      __show: {
        created_uid: "",
        updated_uid: "",
      },
    });

    const details = ref({
      lastname: "",
      firstname: "",
      email: "",
      address: "",
      website: "",
    });

    /******************************** 
      获取下拉框数据 
      merchant_id 商户
      country_iso_2国家
    *********************************/
    const options = ref({
      merchant_id: [],
      position: [] as any[],
      positionLoading: false,
      positionLoad: false,
      report_to: [],
      department: [] as any[],
      departmentLoading: false,
      departmentLoad: false,
      decision_level: [],
    });

    const getCountryOptions = () => {
      ApiContacts.getContactData()
        .then(({ data }) => {
          if (data.code == 0) {
            options.value.report_to = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const getMerchantSourceData = async (value, id?) => {
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getMerchantSourceData(params);
      if (data.code == 0) {
        options.value.merchant_id = data.data;
      }
    };

    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );

    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };

    /******************************** 
      获取下拉框数据 end
    *********************************/

    const validatePassPhone = (rule: any, value: any, callback: any) => {
      let { email, mobile, wechat } = formRef.value?.model;
      if (value === "" && email == "" && mobile == "" && wechat == "") {
        callback(
          new Error("Phone or Email or Mobile or WeChat Account is required")
        );
        // formRef.value?.validateField("email");
      } else {
        formRef.value?.clearValidate(["email", "mobile", "wechat"]);
        callback();
      }
    };

    const validatePassEmail = (rule: any, value: any, callback: any) => {
      let { phone, mobile, wechat } = formRef.value?.model;
      if (value === "" && phone == "" && mobile == "" && wechat == "") {
        callback(
          new Error("Phone or Email or Mobile or WeChat Account is required")
        );
      } else {
        formRef.value?.clearValidate(["phone", "mobile", "wechat"]);
        callback();
      }
    };

    const validatePassMobile = (rule: any, value: any, callback: any) => {
      let { email, phone, wechat } = formRef.value?.model;
      if (value === "" && email == "" && phone == "" && wechat == "") {
        callback(
          new Error("Phone or Email or Mobile or WeChat Account is required")
        );
      } else {
        formRef.value?.clearValidate(["email", "phone", "wechat"]);
        callback();
      }
    };

    const validatePassWechat = (rule: any, value: any, callback: any) => {
      let { email, mobile, phone } = formRef.value?.model;
      if (value === "" && email == "" && mobile == "" && phone == "") {
        callback(
          new Error("Phone or Email or Mobile or WeChat Account is required")
        );
      } else {
        formRef.value?.clearValidate(["email", "mobile", "phone"]);
        callback();
      }
    };

    const rules = ref({
      salutation: [
        {
          required: true,
          message: "Salutation is required",
          trigger: "change",
        },
      ],
      firstname: [
        {
          required: true,
          message: "First Name is required",
          trigger: "change",
        },
      ],
      lastname: [
        {
          required: true,
          message: "Last Name is required",
          trigger: "change",
        },
      ],
      merchant_id: [
        {
          required: true,
          message: "Parent Merchant is required",
          trigger: "change",
        },
      ],
      position: [
        {
          required: true,
          message: "Position is required",
          trigger: "change",
        },
      ],
      phone: [
        {
          validator: validatePassPhone,
          trigger: "change",
        },
      ],
      email: [
        {
          validator: validatePassEmail,
          trigger: "change",
        },
      ],
      mobile: [
        {
          validator: validatePassMobile,
          trigger: "change",
        },
      ],
      wechat: [
        {
          validator: validatePassWechat,
          trigger: "change",
        },
      ],
    });

    // const tagging = ref({
    //   department: {},
    //   position: {},
    //   decision_level: {},
    // });

    const getContactInfo = () => {
      loading.value = true;
      ApiContacts.getContactInfo({ id: route.params.id })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            formData.value = data.data;
            let { lastname, firstname, email, address, website } = JSON.parse(
              JSON.stringify(data.data)
            );
            details.value = {
              lastname,
              firstname,
              email,
              address,
              website,
            };
            getMerchantSourceData("", data.data.merchant_id);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      ApiBase.getTaggingData({
        // "global_department",
        short_key: ["global_decision_level"],
      })
        .then(({ data }) => {
          if (data.code == 0) {
            // options.value.department = data.data.global_department.items;
            options.value.decision_level =
              data.data.global_decision_level.items;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const querySearchAsync = async (
      queryString: string,
      cb: (arg: any) => void
    ) => {
      if (options.value.positionLoading) return cb([]);
      if (options.value.positionLoad) {
        let filterArr: any[] = options.value.position.filter(
          (item) =>
            item.value.toUpperCase().indexOf(queryString.toUpperCase()) > -1
        );
        cb(filterArr);
        return false;
      }
      options.value.positionLoading = true;
      const { data } = await ApiContacts.getPositionData({});
      options.value.positionLoading = false;
      if (data.code == 0) {
        let filterArr: any[] = data.data.filter(
          (item) =>
            item.value.toUpperCase().indexOf(queryString.toUpperCase()) > -1
        );
        cb(filterArr);
        options.value.position = [...data.data];
        options.value.positionLoad = true;
      }
    };

    const querySearchAsyncDepartment = async (
      queryString: string,
      cb: (arg: any) => void
    ) => {
      if (options.value.departmentLoading) return cb([]);
      if (options.value.departmentLoad) {
        let filterArr: any[] = options.value.department.filter(
          (item) =>
            item.value.toUpperCase().indexOf(queryString.toUpperCase()) > -1
        );
        cb(filterArr);
        return false;
      }
      options.value.departmentLoading = true;
      const { data } = await ApiContacts.getDepartmentData({});
      options.value.departmentLoading = false;
      if (data.code == 0) {
        let filterArr: any[] = data.data.filter(
          (item) =>
            item.value.toUpperCase().indexOf(queryString.toUpperCase()) > -1
        );
        cb(filterArr);
        options.value.department = [...data.data];
        options.value.departmentLoad = true;
      }
    };

    const deleteContacts = () => {
      Swal.fire({
        text: "Are you sure you would like to deactivate your Contacts?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (deleteButton.value) {
            deleteButton.value.setAttribute("data-kt-indicator", "on");
          }
          loading.value = true;
          ApiContacts.deleteContact({ id: route.params.id })
            .then(({ data }) => {
              loading.value = false;
              deleteButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                Swal.fire({
                  text: "Your Contacts has been deactivated!",
                  icon: "success",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "contacts" });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiContacts.updateContact(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg();
                let { lastname, firstname, email, address, website } =
                  JSON.parse(JSON.stringify(formData.value));
                details.value = {
                  lastname,
                  firstname,
                  email,
                  address,
                  website,
                };
                options.value.positionLoad = false;
                options.value.departmentLoad = false;
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const init = () => {
      getContactInfo();
      getCountryOptions();
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
      init();
    });

    return {
      commonChangeDefaultDate,
      loading,
      details,
      formData,
      rules,
      options,
      formRef,
      submitButton,
      deleteButton,
      querySearchAsync,
      querySearchAsyncDepartment,
      t,
      submit,
      getContactInfo,
      deleteContacts,
      searchMerchantSourceItems,
    };
  },
});
